import React from "react";
import "../App.css";
import Layout from "../components/Layout";
import About from "../components/About";

const Index = () => {
  return (
    <Layout>
      <About />
    </Layout>
  );
};

export default Index;
