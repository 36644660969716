import React, { useState, useEffect } from "react";
import { fetchProjectsData } from "../projectsFirestoreService";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import "animate.css";
import { FadeIn } from "./FadeIn";

const Devs = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const fetchedProject = await fetchProjectsData();
        setProjects(fetchedProject);
      } catch (error) {}
    };

    fetchProject();
  }, []);

  // Filter projects with active set to true
  const activeProjects = projects.filter((project) => project.active === true);

  return (
    <FadeIn>
      <ul className='mx-auto px-6 py-8 grid grid-cols-1 lg:grid-cols-3 gap-8 drop-shadow-sm'>
        {activeProjects.map((project) => (
          <li className='flex-shrink-0 p-6 drop-shadow-sm rounded-xl ring-1 ring-inset ring-kats-pink'>
            <a href={project.projectURL} target='_blank' rel='noreferrer'>
              {project.videoURL ? (
                <video
                  autoPlay
                  muted
                  loop
                  className='w-full h-auto rounded-xl transition duration-700 hover:scale-105 bg-kats-tan-li shadow-md'
                >
                  <source src={project.videoURL} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={project.imageURL}
                  alt={project.name}
                  className='transition duration-700 hover:scale-105 rounded-xl bg-kats-tan-li shadow-md'
                  loading='lazy'
                />
              )}
            </a>

            <div className='text-black text-sm flex-grow'>
              <h3 className='pt-6 pb-2 font-semibold text-lg tracking-tight'>
                {project.name}
              </h3>
              <div className='text-sm'>{project.description}</div>

              <p className='py-1'>
                {" "}
                <span className='font-semibold mr-1'>Frontend:</span>
                {project.frontend}
              </p>
              <p className='py1'>
                {" "}
                <span className='font-semibold mr-1'>Backend:</span>
                {project.backend}
              </p>
              <p className='py-1'>
                {" "}
                <span className='font-semibold mr-1'>Database:</span>
                {project.database}
              </p>

              <a
                href={project.projectURL}
                className='text-sm font-semibold leading-6 text-black py-1'
                target='_blank'
                rel='noreferrer'
              >
                <ArrowTopRightOnSquareIcon className='transition duration-700 hover:scale-105 mt-5 h-6 w-6 text-black hover:text-kats-pink' />
              </a>
            </div>
          </li>
        ))}
      </ul>
    </FadeIn>
  );
};

export default Devs;
