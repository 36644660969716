import React from "react";
import { BuildingOffice2Icon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import emailjs from "@emailjs/browser";

const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const userId = process.env.REACT_APP_EMAILJS_USER_ID;

const ContactF = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Service ID:", serviceId);
    console.log("Template ID:", templateId);
    console.log("User ID:", userId);

    emailjs.send(serviceId, templateId, formData, userId).then(
      (result) => {
        console.log(result.text);
        setIsSent(true);
      },
      (error) => {
        console.log(error.text);
      }
    );
    setFormData({ name: "", email: "", message: "" });
  };
  return (
    <div className='mx-auto max-w-6xl bg-kats-tan-li rounded-xl my-16 drop-shadow-md'>
      {isSent && (
        <div className='text-xl relative mt-4 rounded border border-green-400 bg-green-100 px-4 py-3 text-green-700'>
          <strong className='font-bold'>Success!</strong>
          <span className='mx-3 block sm:inline'>
            Your message has been sent. We will be in touch!
          </span>
        </div>
      )}
      <div className='relative isolate'>
        <div className='mx-auto grid max-w-6xl grid-cols-1 lg:grid-cols-2 divide-x divide-kats-pink divide-opacity-25'>
          <div className='relative px-6 pb-10 pt-12 sm:pt-16 lg:static lg:px-8 lg:py-48'>
            <div className='mx-auto max-w-xl lg:mx-0 lg:max-w-lg'>
              <h1 className='text-4xl font-Bebas tracking-tight text-black'>
                Let&apos;s Work
              </h1>
              <p className='mt-6 text-md leading-8 text-black'>
                Thank you for visiting my portfolio site! If you&apos;re
                interested in working together or have any questions, please
                don&apos;t hesitate to reach out to me. Simply fill out the
                contact form with your details and project requirements, and I
                will get back to you as soon as possible. I appreciate your
                interest in my work and look forward to the opportunity to
                collaborate with you.
              </p>
              <dl className='mt-10 space-y-4 text-base leading-7 text-black'>
                <div className='flex gap-x-4'>
                  <dt className='flex-none'>
                    <span className='sr-only'>Address</span>
                    <BuildingOffice2Icon
                      className='h-7 w-6 text-black hover:text-kats-pink'
                      aria-hidden='true'
                    />
                  </dt>
                  <dd>Los Angeles, California</dd>
                </div>
                <div className='flex gap-x-4'>
                  <dt className='flex-none'>
                    <span className='sr-only'>Email</span>
                    <EnvelopeIcon
                      className='h-7 w-6 text-black hover:text-kats-pink'
                      aria-hidden='true'
                    />
                  </dt>
                  <dd>
                    <a
                      className='text-black hover:text-kats-pink'
                      href='mailto:hello@katcontreras.io'
                    >
                      hello@katcontreras.dev
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className='px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48'
          >
            <div className='mx-auto max-w-xl lg:mr-0 lg:max-w-lg'>
              <div className='grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2'>
                <div className='sm:col-span-2'>
                  <label
                    htmlFor='name'
                    className='block text-sm font-semibold leading-6 text-black uppercase'
                  >
                    Name
                  </label>
                  <div className='mt-2.5'>
                    <input
                      type='text'
                      name='name'
                      id='full-name'
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className='block w-full rounded-md border-0 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset bg-kats-tan ring-kats-pink placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-kats-pink sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>
                <div className='sm:col-span-2'>
                  <label
                    htmlFor='email'
                    className='block text-sm font-semibold leading-6 text-black uppercase'
                  >
                    Email
                  </label>
                  <div className='mt-2.5'>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      value={formData.email}
                      onChange={handleChange}
                      required
                      autoComplete='email'
                      className='block w-full rounded-md border-0 px-3.5 py-2 text-black shadow-sm bg-kats-tan ring-1 ring-inset ring-kats-pink placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-kats-pink sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>

                <div className='sm:col-span-2'>
                  <label
                    htmlFor='message'
                    className='block text-sm font-semibold leading-6 text-black uppercase'
                  >
                    Message
                  </label>
                  <div className='mt-2.5'>
                    <textarea
                      name='message'
                      id='message'
                      rows={8}
                      className='block w-full rounded-md border-0 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset bg-kats-tan ring-kats-pink placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-kats-pink sm:text-sm sm:leading-6'
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                ref={captchaRef}
                className='py-2'
              /> */}
              <div className='mt-8 flex justify-end'>
                <button
                  type='submit'
                  className='transition duration-700 hover:scale-105 uppercase rounded-md border-0 ring-1 ring-inset ring-kats-pink px-3.5 py-2.5 text-center text-sm font-semibold shadow-md bg-kats-tan-li hover:text-kats-tan-li hover:bg-kats-pink  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-kats-pink'
                >
                  let&apos;s work
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactF;
