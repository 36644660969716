import firestore from "./firebaseConfig";

export const fetchProjectsData = async () => {
  try {
    const projectsSnapshot = await firestore
      .collection("Projects")
      .orderBy("id", "desc")
      .get();
    const projectsData = projectsSnapshot.docs.map((doc) => doc.data());

    // console.log("Fetched projects data:", projectsData);
    return projectsData;
  } catch (error) {
    console.error("Error fetching Firestore data:", error);
    throw error;
  }
};
