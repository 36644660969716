import firestore from "./firebaseConfig";

export const fetchUpdatesData = async () => {
  try {
    const updatesSnapshot = await firestore
      .collection("Updates")
      .orderBy("id", "desc") // Order by timestamp in descending order
      .get();
    const updatesData = updatesSnapshot.docs.map((doc) => doc.data());

    // console.log("Fetched updatesData:", updatesData);

    return updatesData;
  } catch (error) {
    console.error("Error fetching Firestore data:", error);
    throw error;
  }
};
