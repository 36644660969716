import "animate.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <div className='bg-wallpaper bg-cover'>
      <Navbar />
      <div className='flex flex-col min-h-screen animate__animated animate__fadeIn '>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
}
