import React, { useState, useEffect } from "react";
import { fetchUpdatesData } from "../updatesFirestoreService";
import Layout from "../components/Layout";
import Tuts from "../components/Tuts";

function Updates() {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    const fetchUpdate = async () => {
      try {
        const fetchedUpdate = await fetchUpdatesData();
        setUpdates(fetchedUpdate);
      } catch (error) {
        // Handle error or show error message
      }
    };

    fetchUpdate();
  }, []);
  return (
    <Layout>
      <div className='mx-auto max-w-6xl bg-kats-tan-li rounded-xl my-16 drop-shadow-md'>
        <div className='mx-auto px-6 pt-14 lg:px-8 py-6'>
          <h1 className='text-4xl font-Bebas tracking-tight text-black'>
            Tech for Good
          </h1>
          <p className='mt-6 text-lg leading-8 text-black'>
            My current projects include building a membership database for
            Authority Collective that leverages machine learning and AI for
            semantic search; co-producing and designing a visuals-centric hybrid
            zine called Veer and participating in Out in Tech's digital corps.
          </p>
        </div>

        <Tuts />
      </div>
    </Layout>
  );
}

export default Updates;
