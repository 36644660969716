import React, { useEffect } from "react";
import { gtag, install } from "ga-gtag";

import "./App.css";
import "./output.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "tailwindcss/tailwind.css";

import Index from "./screens";
import Contact from "./screens/constact";
import Projects from "./screens/projects";
import Updates from "./screens/update";

install(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);

function App() {
  useEffect(() => {
    // Track a page view
    gtag("config", process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);
  }, []);
  return (
    <>
      <Router>
        <Routes>
          <Route exact path='/' element={<Index />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/projects' element={<Projects />} />

          <Route exact path='/techforgood' element={<Updates />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
