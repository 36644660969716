import React, { useState, useEffect } from "react";
import { fetchUpdatesData } from "../updatesFirestoreService";

const Tuts = () => {
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    const fetchUpdate = async () => {
      try {
        const fetchedUpdate = await fetchUpdatesData();
        setUpdates(fetchedUpdate);
      } catch (error) {
        // Handle error or show error message
      }
    };

    fetchUpdate();
  }, []);
  return (
    <div className='mx-auto px-6 py-8 grid grid-cols-1 lg:grid-cols-2 gap-8 drop-shadow-sm'>
      {" "}
      {updates.map((update) => (
        <div className='flex flex-col w-full p-6 drop-shadow-sm'>
          <div className='flex-shrink-0'>
            {update.videoURL ? (
              <video
                autoPlay
                muted
                loop
                className='w-full h-auto rounded-xl bg-kats-tan-li shadow-md'
              >
                <source src={update.videoURL} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={update.imageURL}
                alt={update.name}
                className='w-full h-auto rounded-xl bg-kats-tan-li shadow-md object-cover'
                loading='lazy'
              />
            )}
          </div>
          <div className='flex-grow'>
            <h3 className='pt-6 pb-2 font-semibold text-xl tracking-tight'>
              {update.name}
            </h3>
            <div>
              <p>{update.description}</p>
            </div>
            <div className='py-3'>
              <a
                href={update.projectURL}
                target='_blank'
                rel='noopener noreferrer'
              >
                <p className='font-bold hover:underline'>{update.projectURL}</p>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Tuts;
