import React from "react";

const About = () => {
  return (
    <div className='mx-auto max-w-6xl bg-kats-tan-li rounded-xl my-16 drop-shadow-md'>
      <div className='relative isolate overflow-hidden rounded-xl bg-kats-pink'>
        <div
          className='absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-kats-tan-li shadow-kats-pink/10 ring-1 ring-kats-pink sm:-mr-80 lg:-mr-96'
          aria-hidden='true'
        />
        <div className='mx-auto max-w-7xl px-6 py-10 lg:px-8 '>
          <div className='mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8'>
            <h1 className='max-w-2xl text-6xl font-Bebas tracking-tight text-black lg:col-span-2 xl:col-auto mb-6 md:mb-0 '>
              People first, <br /> technology second.
            </h1>
            <div className='max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1'>
              <p className='text-md leading-8 text-black'>
                I am a creative technologist and visual storyteller with
                experience as a technology leader, web developer and digital
                media producer for personal brands, small businesses and
                nonprofit organizations.
              </p>
              <p className='text-md leading-8 text-black py-3'>
                I thrive at the intersection of media and technology, and am
                passionate about creating beautiful, accessible, and inclusive
                designs, technological solutions, and visual stories that
                provide unique perspectives and improve the lives of those who
                touch and/or see them.
              </p>
              <div className='mt-10 flex items-center gap-x-6'>
                <a
                  href='/contact'
                  className='transition duration-700 hover:scale-105 rounded-md ring-1 ring-inset bg-kats-tan-li ring-kats-pink px-3.5 py-2.5 text-sm font-semibold text-black shadow-md hover:text-kats-tan-li hover:bg-kats-pink focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-kats-pink uppercase'
                >
                  Let's Work
                </a>
                <a
                  href='/projects'
                  className='transition duration-700 hover:scale-105 text-sm font-semibold leading-6 text-black uppercase'
                >
                  Latest projects <span aria-hidden='true'>→</span>
                </a>
              </div>
            </div>

            <img
              src='https://kats-port.s3.us-west-1.amazonaws.com/katcontreras_headshot_23.jpg'
              alt='black and white headshot of kat contreras'
              className='invisible md:visible mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36'
              width={512}
              height={427}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
